import service from "@/http";
// 获取国家列表
export const getareacode = (data) => {
  return service({
    url: "/getareacode",
    method: "get",
    params: data,
  });
};

// 获取邮箱
export const getsms_mail = (data) => {
  return service({
    url: "/sms_mail",
    method: "post",
    data: data,
  });
};

// 修改邮箱
export const editEmai = (data) => {
  return service({
    url: "/safe/email",
    method: "post",
    data: data,
  });
};

// 修改登录密码
export const forgetPass = (data) => {
  return service({
    url: "/user/forget",
    method: "post",
    data: data,
  });
};

// 修改支付密码
export const update_password = (data) => {
  return service({
    url: "/safe/update_password",
    method: "post",
    data: data,
  });
};

// 获取帮助中心、关于我们
export const getNewsList = (data) => {
  return service({
    url: "/news/list",
    method: "post",
    data: data,
  });
};
