<template>
  <div class="heloCenter">
    <div class="leftBox">
      <div
        class="titleItem"
        :class="{ active: item.id == activeItme }"
        v-for="(item, index) in leftlist"
        @click="activeItme = item.id"
      >
        <span>{{ item.title }}</span>
      </div>
    </div>
    <div class="rightBox">
      <p v-html="htmlContent"></p>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, getCurrentInstance, watch } from "vue";
import { getNewsList } from "@/api/common";
import { useLang } from "@/hooks/useLang";
const { lang } = useLang();
const vm = getCurrentInstance();
const leftlist = ref([]);
const activeItme = ref(null);
const htmlContent = computed(() => {
  const arr = leftlist.value.filter((item) => item.id == activeItme.value);
  return arr.length > 0 ? arr[0].content : "";
});
const initData = async () => {
  leftlist.value = [];
  const { message } = await getNewsList({
    lang: lang.value,
    c_id: vm.proxy.$route.query.id,
  });
  leftlist.value = message.list;
  activeItme.value = leftlist.value[0].id;
};
watch(
  () => vm.proxy.$route.query.id,
  () => {
    initData();
  },
  {
    immediate: true,
  }
);
</script>

<style lang="scss" scoped>
.heloCenter {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  .leftBox {
    flex: 1;
    width: 10px;
    height: fit-content;
    border-radius: 10px;
    box-shadow: $box-shadow;
    padding: 15px;
    box-sizing: border-box;
    background-color: $section-color;
    .titleItem {
      height: 41px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
      cursor: pointer;
      font-size: 14px;
      &.active {
        color: #fff;
        background-color: #5670f6;
      }
    }
  }
  .rightBox {
    flex: 3;
    box-shadow: $box-shadow;
    width: 10px;
    margin-left: 30px;
    background-color: $section-color;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
  }
}
</style>
